<template>
  <v-snackbar v-model="snackbar.show" :timeout="timeout" multi-line transition="slide-y-reverse-transition">
    <div style="min-width: 400px">
      {{ snackbar.text }}
    </div>
    <v-slide-x-transition>
      <v-sheet v-if="showInfo && snackbar.info" class="pa-3 mt-2 rounded-lg grey darken-4" style="max-width: 400px">
        <div v-if="vanillaException">
          <p class="font-weight-bold">
            {{ vanillaException.name }}
          </p>
          <p>
            {{ vanillaException.message }}
          </p>
          <p v-if="vanillaException.furtherInformationHTML" v-html="vanillaException.furtherInformationHTML"/>
          <div class="caption grey--text">{{ vanillaException.namespace }}::{{ vanillaException.type }}</div>
          <div v-if="vanillaException.origin?.length > 0" class="caption grey--text" @click="showFullExceptionStack = !showFullExceptionStack">
            <div> von {{ vanillaException.origin[0].fn }} aus
              {{ vanillaException.origin[0].file }}
            </div>
            <v-slide-y-transition hide-on-leave>
              <div v-if="showFullExceptionStack">
                <div v-for="(origin, i) in vanillaException.origin?.slice(1)" :key="i">
                  <div v-if="origin.file">
                    aufgerufen von {{ origin.fn }} aus {{ origin.file }}
                  </div>
                  <div v-else>
                    aufgerufen von {{ origin.fn }}
                  </div>
                </div>
              </div>
            </v-slide-y-transition>
          </div>
        </div>
        <pre v-else style="white-space: break-spaces">{{ snackbar.info }}</pre>
      </v-sheet>
    </v-slide-x-transition>
    <template v-if="snackbar.actions.length > 0" v-slot:action>
      <v-btn v-for="(action, i) in snackbar.actions" :key="i" :color="action.color || 'info'" text @click="action.fn">
        {{ action.text }}
      </v-btn>
      <v-btn v-if="snackbar.info" :color="snackbar.btnColor" icon @click="showInfo = !showInfo">
        <v-icon v-if="snackbar.btnColor === 'error'">mdi-alert-outline</v-icon>
        <v-icon v-else>mdi-information-outline</v-icon>
      </v-btn>
      <v-btn color="grey" icon @click="hideSnackbar">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
    <template v-else-if="snackbar.info" v-slot:action>
      <v-btn v-if="snackbar.info" :color="snackbar.btnColor" icon @click="showInfo = !showInfo">
        <v-icon v-if="snackbar.btnColor === 'error'">mdi-alert-outline</v-icon>
        <v-icon v-else>mdi-information-outline</v-icon>
      </v-btn>
      <v-btn color="grey" icon @click="hideSnackbar">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
    <template v-else v-slot:action>
      <v-btn :color="snackbar.btnColor" text @click="hideSnackbar"> Schließen</v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import Vue from 'vue';
import {VanillaException} from '@/exceptions/VanillaException';

export default Vue.extend({
  data: () => ({
    timeoutPaused: false,
    showInfo: false,
    showFullExceptionStack: false,
  }),
  computed: {
    timeout(): number {
      return this.timeoutPaused ? -1 : this.$store.state.snackbar.timeout;
    },
    snackbar() {
      return this.$store.state.snackbar;
    },
    vanillaException(): VanillaException | undefined {
      const snackbarInfo = this.snackbar.info;
      if (snackbarInfo && snackbarInfo instanceof VanillaException) {
        return snackbarInfo;
      }
      return undefined;
    },
  },
  methods: {
    hideSnackbar() {
      this.$store.commit('hideSnackbar')
    },
    pauseTimeoutIfWindowIsHidden() {
      if (document.hidden) {
        this.handleWindowBlur()
      } else {
        this.handleWindowFocus()
      }
    },
    handleWindowBlur() {
      this.timeoutPaused = true;
    },
    handleWindowFocus() {
      if (!this.showInfo) {
        this.timeoutPaused = false;
      }
    },
  },
  watch: {
    'snackbar.show'(v: boolean) {
      if (!v) {
        this.showInfo = false;
        this.showFullExceptionStack = false;
      }
    },
    showInfo(v: boolean) {
      if (v) {
        this.timeoutPaused = true;
      } else {
        if (this.$store.state.snackbar.show) {
          this.timeoutPaused = false;
        }
      }
    },
  },
  mounted() {
    document.addEventListener('visibilitychange', this.pauseTimeoutIfWindowIsHidden);
    window.addEventListener('blur', this.handleWindowBlur);
    window.addEventListener('focus', this.handleWindowFocus);
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.pauseTimeoutIfWindowIsHidden);
    window.removeEventListener('blur', this.handleWindowBlur);
    window.removeEventListener('focus', this.handleWindowFocus);
  },
});
</script>
