import {toCurrencyString} from '@/helpers/toCurrencyString.helper';

export {}

declare global {
    interface Number {
        toCurrencyString(fractionDigits?: number, currency?: string): string;
    }
}

if (!Number.prototype.toCurrencyString) {
    Number.prototype.toCurrencyString = function(this: number, fractionDigits?: number, currency?: string): string {
        return toCurrencyString(this, fractionDigits, currency);
    }
}
