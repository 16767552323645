import {mapRecordToItems} from '@/helpers/mapRecordToItems.helper';

export enum EnvTypes {
    PRODUCTION = 'production',
    LOCAL = 'local',
    TEST = 'testing',
}

export namespace EnvTypes {
    type Data = {
        text: string;
    };

    export const Record: Record<EnvTypes, Data> = {
        [EnvTypes.PRODUCTION]: {text: 'Produktivsystem'},
        [EnvTypes.TEST]: {text: 'Testserver'},
        [EnvTypes.LOCAL]: {text: 'Lokale Entwicklung'},
    };

    export const Items = mapRecordToItems(Record);
}
