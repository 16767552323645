import {Types} from 'mongoose';
import {API} from './api.class';
import {CreateWarehouseOrderRequestDto} from '@/classes/dto/warehouse-manager/request/CreateWarehouseOrder.request.dto';
import {WarehouseOrder} from '@/interfaces/warehouseOrder.interface';
import {
    GetArticlesWithPendingOrdersRequestDto,
} from '@/classes/dto/warehouse-manager/request/GetArticlesWithPendingOrders.request.dto';
import {GetOrderNumbersRequestDto} from '@/classes/dto/warehouse-manager/request/GetOrderNumbers.request.dto';
import {
    GetArticlesWithPendingOrdersResponseDto,
} from '@/classes/dto/warehouse-manager/response/GetArticlesWithPendingOrders.response.dto';
import {GetOrderNumbersResponseDto} from '@/classes/dto/warehouse-manager/response/GetOrderNumbers.response.dto';
import {GetStockRequestDto} from '@/classes/dto/warehouse-manager/request/GetStock.request.dto';
import {FindWarehouseOrderRequestDto} from '@/classes/dto/warehouse-manager/request/FindWarehouseOrder.request.dto';
import {FindByIdOptions} from '@/interfaces/findByIdOptions';
import {UpdateWarehouseOrderRequestDto} from '@/classes/dto/warehouse-manager/request/UpdateWarehouseOrder.request.dto';
import {
    MassFulfillWarehouseOrderRequestDto,
} from '@/classes/dto/warehouse-manager/request/MassFulfillWarehouseOrder.request.dto';
import {BulkUpdateResponseDto} from '@/classes/dto/_common/response/bulkUpdate.response.dto';
import {
    BulkUpdateWarehouseOrderRequestDto,
} from '@/classes/dto/warehouse-manager/request/BulkUpdateWarehouseOrder.request.dto';

/**
 * This class is used for communicating with the warehouse manager microservice.
 * @author Joshua Seipel
 */
export class WarehouseManagerAPI extends API {
    static service = 'warehouse-manager';

    static async createOrder(order: CreateWarehouseOrderRequestDto): Promise<WarehouseOrder> {
        return this.post(``, order);
    }

    static async getStock(articleId: Types.ObjectId, data: GetStockRequestDto): Promise<number> {
        return this.get(`stock/${articleId}`, data);
    }

    static async findById(id: Types.ObjectId, options?: FindByIdOptions): Promise<WarehouseOrder> {
        return this.get(`${id}`, options);
    }

    static async find(data: FindWarehouseOrderRequestDto): Promise<{
        warehouseOrders: WarehouseOrder[];
        maxPage: number
    }> {
        return this.get(``, data);
    }

    static async massFulfill(update: MassFulfillWarehouseOrderRequestDto): Promise<void> {
        return this.put('mass-fulfill', update);
    }

    static async update(id: Types.ObjectId, update: UpdateWarehouseOrderRequestDto): Promise<void> {
        return this.put(`${id}`, update);
    }

    static async bulkUpdate(bulkUpdateData: BulkUpdateWarehouseOrderRequestDto): Promise<BulkUpdateResponseDto> {
        return this.put(`bulk`, bulkUpdateData);
    }

    static async getArticlesWithPendingOrders(data: GetArticlesWithPendingOrdersRequestDto): Promise<GetArticlesWithPendingOrdersResponseDto> {
        return this.get(`aggregation/articles-with-pending-orders`, data);
    }

    static async getOrderNumbers(data: GetOrderNumbersRequestDto): Promise<GetOrderNumbersResponseDto> {
        return this.get('aggregation/order-numbers', data);
    }

    static async remove(id: Types.ObjectId): Promise<void> {
        return this.delete(`${id}`);
    }
}
