<template>
  <autocomplete-preset :api="api" :fields="['type', 'env', 'name', 'phoneNumber']"
                       :filter="computedFilter" :resp-mapper="respMapper"
                       :value="value"
                       v-bind="$props" @input="$emit('input', $event)"/>
</template>
<script lang="ts">
import AutocompleteMixin from '@/mixins/autocompletes/Autocomplete.mixin';
import {FindApiConfigurationFilter} from '@/classes/dto/api-handler/request/filters/FindApiConfiguration.filter';
import {ApiConfigurationDocument} from '@/documentTypes/api-handler/ApiConfiguration';
import {EnvTypes} from '@/enums/_common/EnvTypes.enum';
import {ApiHandlerApi} from '@/classes/api/api-handler.api';
import {ApiTypesEnum} from '@/enums/api-handler/ApiTypes.enum';
import {WhatsAppApiConfigurationDocument} from '@/documentTypes/api-handler/WhatsAppApiConfiguration';

export default AutocompleteMixin.extend({
  props: {
    filter: {type: Object as () => FindApiConfigurationFilter, required: false},
    type: {type: Array as () => ApiTypesEnum[], required: false},
    onlyAccessible: {type: Boolean, required: false},
  },
  computed: {
    api: () => ApiHandlerApi,
    computedFilter() {
      const filter: FindApiConfigurationFilter = this.filter || {};
      if (this.type) {
        filter.type = this.type;
      }
      if (this.onlyAccessible) {
        filter.isAccessibleForMe = true;
      }
      return filter;
    },
  },
  methods: {
    respMapper(resp: { apiConfigurations: ApiConfigurationDocument[] }) {
      return resp.apiConfigurations.map((el) => {
        let envStates: string[] = [];
        for (const env of el.env) {
          envStates.push(EnvTypes.Record[env].text);
        }
        let text = el.name;
        if (el.type === ApiTypesEnum.WHATS_APP) {
          text += ` (${(el as WhatsAppApiConfigurationDocument).phoneNumber})`;
        }

        return {
          text,
          description: envStates.join(', '),
          value: el._id,
        };
      });
    },
  },
});
</script>
